import parser from "html-react-parser";
import { capitalize, get, has, isEmpty, isEqual } from "lodash";
import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import { Grid } from "@material-ui/core";

import FlowFontAwesomeIcon from "../../components/icons/FlowFontAwesomeIcon";
import EnquiryFormContainer from "../../containers/EnquiryFormContainer";
import MessageFormContainer from "../../containers/MessageFormContainer";
import { ApplicationState } from "../../store";
import { AuthenticatedUser } from "../../store/auth";
import { Property } from "../../store/chat";
import {
  DialogsState,
  toggleLoginDialog as toggleLoginDialogAction,
  toggleMessageDialog as toggleMessageDialogAction,
  toggleProfileDialog as toggleProfileDialogAction,
} from "../../store/dialogs";
import { Listing, PartnerProps, Unit, Video } from "../../store/listings";
import * as styledTheme from "../../styles/theme";
import { getAddressDetailsFromListing } from "../../utils/addressFormatter";
import { DEFAULT_LOCALE_SETTINGS } from "../../utils/defaultLocaleSettings";
import { addGTMDataLayer } from "../../utils/GoogleTagManager";
import { getCoverImage } from "../../utils/imageUrls";
import {
  formatNumber,
  getSquaredMeasurement,
} from "../../utils/numberFormatter";
import {
  MEASUREMENT_TYPES,
  PropertyItem,
} from "../../utils/propertyOverviewItems";
import { IsMobile } from "../../utils/responsive";
import styled, { mediaDown, mediaUp } from "../../utils/styledComponents";
import PartnersCarousal from "../carousel/PartnersCarousel";
import RecommendedCarousal from "../carousel/RecommendedCarousel";
import DialogWithImage from "../dialogs/DialogWithImage";
import UnitListGallery from "../gallery/UnitListGallery";
import AvailableUnitList from "../lists/AvailableUnitList";
import AuctionOverviewTable from "../tables/auctionDetailsTable";
import PropertyOverviewTable from "../tables/propertyOverviewTable";
import ListingFeature from "../ui/ListingFeature";
import Paper from "../ui/Paper";
// import StarRating from "../ui/StarRating";
import VideoPlayer from "../ui/videoPlayer";
import ContactAgentWidget from "../widgets/ContactAgentWidget";
import ExtraWidget from "../widgets/ExtraWidget";
import MapWidget from "../widgets/MapWidget";
import PriceWidget from "../widgets/PriceWidget";
import PropertyAgentWidget from "../widgets/PropertyAgentWidget";
import ReadMore from "../links/ReadMore";
import { getWhiteLabelOrganisationColors } from "../../utils/whiteLabel";
import { getUnitPrice } from "../../utils/units.helper";

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
`;

const StyledHr = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  color: ${({ theme }) => theme.colors.grey1};
  border-bottom: solid 1px;
`;

const StyledFeatures = styled(DescriptionContainer as any)`
  && {
    padding: 10px 0px;
  }
`;

const StyledPropertyOverviewContainer = styled(DescriptionContainer as any)`
  && {
    padding: 10px 0px;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${mediaUp.sm`padding-right: 40px;`}
`;

const StyledLeftGrid = styled(Grid)`
  && {
    ${mediaDown.sm`width: 100%;`};
    ${mediaUp.sm`
    flex-grow: 0;
    max-width: 66%;
    flex-basis: 66.666667%;
  `}

    ${mediaDown.md`
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
    -webkit-flex-basis: 66.666667%;
    -ms-flex-preferred-size: 66.666667%;
    flex-basis: 100%;
  `}
  }
`;

const StyledRightGrid = styled(Grid)`
  && {
    ${mediaDown.sm`width: 100%;`};
    ${mediaUp.sm`
    flex-grow: 0;
    max-width: 34.333333%;
    flex-basis: 34%;
  `}
  }
`;

const ContentCainter = styled.div`
  ${mediaUp.sm`padding-right: 20px;`}
`;

const GroupedComponent = styled.div`
  display: flex;
  flex-direction: row;
`;

const Location = styled.span`
  && {
    font-size: 18px;
    font-family: ${({ theme }) => theme.fonts.MS500};
    color: ${({ theme }) => theme.colors.grey2};
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const PropertyName = styled.span`
  color: ${({ theme }) => theme.colors.grey4};
  font-family: ${({ theme }) => theme.fonts.MS700};
  font-weight: ${({ theme }) => theme.fontWeights.MS700};
  font-size: 24px;
  margin-bottom: 7px;
  ${mediaDown.sm`font-size: 20px`}
`;

const GroupedHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: 20px;
`;

const StyledParagraph = styled.p`
  color: ${({ theme }) => theme.colors.grey2};
  font-family: ${({ theme }) => theme.fonts.MS300};
  font-weight: ${({ theme }) => theme.fontWeights.MS300};
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 20px;
`;

const StyledPaper = styled(Paper as any)`
  && {
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto;
    padding: 5px 10px;
    ${mediaDown.sm`box-shadow: none; margin-bottom: 150px`}
  }
` as any;

// const StyledRating = styled(StarRating)`
//   && {
//     align-self: flex-start;
//     margin-right: 0px;
//     div {
//       margin-right: 0px;
//     }
//   }
// `;

const GroupedItems = styled.div`
  display: flex;
  flex-direction: row;
  ${mediaDown.sm`margin-bottom: 20px`}
  ${mediaDown.sm`justify-content: space-between`}
  display: none;
`;

const StyledH1 = styled.h1`
  margin-left: 10px;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.grey4};
  font-family: ${({ theme }) => theme.fonts.MS700};
  font-weight: ${({ theme }) => theme.fontWeights.MS700};
`;

const StyledIcon = styled(FlowFontAwesomeIcon).attrs(({ theme }) => {
  return { color: theme.colors.grey3 };
})`
  && {
    display: none;
  }
` as any;

const Greyline = styled.div`
  margin-left: -2px;
  margin-right: 10px;
  color: #f0f0f0;
  border-bottom: solid 1px;
`;

const AgentGreyline = styled.div`
  margin-left: -2px;
  margin-right: 10px;
  color: #f0f0f0;
  border-bottom: solid 1px;
  margin-top: 20px;
  ${mediaDown.sm`display: none;`};
`;

const StyledExtraWidget = styled(ExtraWidget)`
  && {
    margin: 0;
  }
`;

const StyledActionsHeaderContainer = styled.div`
  background: ${({ theme }: any) => theme.colors.grey1};
  border-radius: 5px;
  margin: 10px 0px;
  display: none;
  ${mediaDown.sm`display: flex;`};
`;

const StyledActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${mediaDown.sm`flex-direction: column-reverse`};
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
`;

const RoomFeatures = styled.div`
  && {
    display: flex;
    text-align: left;
    flex-wrap: wrap;
    margin: 10px 0px;
    ${mediaDown.sm`margin-bottom: 20px;`};
  }
`;

const StyledFeature = styled.div`
  display: flex;
  text-align: left;
  flex-direction: row;
  margin-bottom: 5px;
  & > svg {
    margin-right: 5px;
  }
`;

const FeatureName = styled.span`
  font-family: ${({ theme }) => theme.fonts.MS300};
  font-weight: ${({ theme }) => theme.fontWeights.MS300};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey3};
  text-align: left;
  margin-right: 7.5px;
  width: auto;
`;

const SizeFeatureName = styled(FeatureName)``;

const PreviewMode = styled.div`
  background: #f3f7fa;
  border: 1px solid #84b8d1;
  border-radius: 10px;
  padding: 10px;
  max-width: 1180px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 10px;
  ${mediaDown.sm`padding: 10px 0px; box-shadow: none; margin: 10px`}
`;

const PreviewText = styled.div`
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.MS500};
  font-weight: ${({ theme }) => theme.fontWeights.MS500};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.lightblue};
  ${mediaDown.sm`font-size: 12px; font-family: ${styledTheme.theme.fonts.MS700};`}
`;

const StyledExtrasContainer = styled.div`
  && {
    display: none;
  }
`;

const StyledAgentContainer = styled.div<{ hidden?: boolean }>`
  && {
    display: none;
    /* line-height: 40px; */
    ${({ hidden }) => (!hidden ? mediaDown.md`display: block;` : null)}
  }
`;

const Styledh2 = styled.h2`
  && {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.grey4};
    font-family: ${({ theme }) => theme.fonts.MS700};
    font-weight: ${({ theme }) => theme.fontWeights.MS700};
    margin: 20px 0px;
  }
`;

const AboutTitle = styled.p`
  && {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.grey4};
    font-family: ${({ theme }) => theme.fonts.MS700};
    font-weight: ${({ theme }) => theme.fontWeights.MS700};
    margin-bottom: 15px;
  }
`;

const StickyPriceTag = styled.div`
  && {
    display: none;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    left: 0;
    right: 0;
    box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.2);
    ${mediaDown.md`display: block;position: fixed;`}
  }
`;

const MapContainer = styled.div`
  && {
    flex-direction: column;
  }
`;
const ParagraphContainer = styled.div`
  & {
    margin-top: 11.5px;
  }
`;

const CarouselContainer = styled.div`
  && {
    margin-top: 20px;
  }
`;

const TourContainer = styled.div`
  & {
    margin-top: 11.5px;
  }
`;

const StyledMap = styled(MapWidget)`
  && {
    margin-top: 15px;
  }
`;

interface PropsFromState {
  authenticated: boolean;
  user?: AuthenticatedUser;
  dialogs: DialogsState;
}
interface PropsFromDispatch {
  toggleLoginDialog: typeof toggleLoginDialogAction;
  toggleProfileDialog: typeof toggleProfileDialogAction;
  toggleMessageDialog: typeof toggleMessageDialogAction;
}
interface OwnProps {
  property: Listing;
  partners: PartnerProps[];
  recommended: Listing[];
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch;

const ListingDetailsView: React.FunctionComponent<AllProps> = ({
  authenticated,
  dialogs,
  property,
  partners,
  recommended,
  toggleMessageDialog,
  user,
}) => {
  if (!isEmpty(recommended)) {
    // remove the current property from recomndation list
    recommended = recommended.filter((rec) => rec._id !== property._id);
  }

  const words = property.description;
  const maxWord = 214;
  const [isMore, setIsMore] = useState<boolean>(false);

  const agentId = get(property, "agentUser", null)
    ? get(property, "agentUser", null)
    : get(property, "agent.flowId", null)
    ? get(property, "agent.flowId", null)
    : get(property, "landlordUser", null)
    ? get(property, "landlordUser", null)
    : "";
  const enquiryProperty: Property = {
    _id: property._id,
    address: {
      city: has(property, "address.city") ? property.address.city : "",
      location: has(property, "address.suburb") ? property.address.suburb : "",
    },
    listingSector: property.listingSector,
    description: property.description,
    title: property.title,
    coverImageUrl: !isEmpty(property.coverImageUrl)
      ? property.coverImageUrl
      : getCoverImage(property),
    organisation: property.organisation,
    propertyType: property.propertyType,
  };

  const externalId = get(property, "syndicator.externalId");
  const isSyndicatedProperty = !isEmpty(externalId);
  const isResidential = isEqual(property.listingSector, "residential");
  const isVehicle = isEqual(property.listingSector, "vehicle");

  const isPropertyHavingMultipleUnits = get(property, "hasUnits", false);
  const hasMultipleUnits =
    isPropertyHavingMultipleUnits && !isSyndicatedProperty;

  if (has(property, "flowExtras")) {
    delete property.flowExtras; // remove extras
  }
  const flowExtrasData = has(property, "flowExtras") ? property.flowExtras : [];
  const unit = property.units[0];
  const openSendMessageDialog: boolean =
    dialogs.openMessageDialog.unitId === unit._id &&
    dialogs.openMessageDialog.openDialog;
  const unitRef = useRef(null);
  const scrollToRef = (ref: any) => {
    ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const executeScroll = () => scrollToRef(unitRef);
  const handleOpenMessageDialog = (): void => {
    addGTMDataLayer({
      data: {
        listingId: unit.property,
        title: get(unit, "title", property.title),
        user: has(user, "id") ? user?.id : undefined,
        price: Number(getUnitPrice(unit, true)),
        listingType: property.listingType,
        province: property.address.province,
        suburb: property.address.suburb,
        city: property.address.city,
        organisation: property.organisation,
      },
      event: "tenant_initiated_message",
      page: "listingDetailsView",
    });

    toggleMessageDialog({
      openDialog: true,
      unitId: unit._id,
    });
  };

  const handleCloseMessageDialog = () => {
    toggleMessageDialog({
      openDialog: false,
      unitId: unit._id,
    });
  };

  const handleReadMore = (Event: React.MouseEvent) => {
    Event.preventDefault();
    setIsMore((prev) => !prev);
  };
  const showPartners = get(
    property,
    "organisationObject.settings.partners.showPartners",
    true
  );
  const organisationName = get(property, "organisationObject.name", "");
  const handlePartnerOnClick = (slug: string) => (Event: React.MouseEvent) => {
    const suburb = get(property, "address.suburb", "");
    const listingType = get(property, "listingType", "Sale");
    const parameters = `/?utm_source=flow_listing&utm_medium=${listingType}_listing&utm_content=${organisationName}&utm_term=${suburb}`;
    const url = `${process.env.REACT_APP_STATIC_PAGES_URL}/partner/${slug}${parameters}`;
    window.open(url);
  };

  const description = isMore ? words : words.substring(maxWord, 0) + ".... ";

  const allPrice = property.units.map((unit: Unit) => unit.rentalAmount);
  const minPrice = Math.min(...allPrice);
  const minUnit = property.units.find((unit) => unit.rentalAmount === minPrice);
  const price = getUnitPrice(minUnit) || 0;

  const urlParams = new URLSearchParams(window.location.search);
  const fullName = `${get(property, "agentProfile.firstName", "")} ${get(
    property,
    "agentProfile.lastName"
  )}`;
  const agentName = get(property, "agentProfile.displayName", fullName);

  const agentProfile = get(property, "agentProfile", undefined);
  const slug = get(property, "organisationObject.slug", undefined);
  const agencyLogo = get(property, "organisationObject.logoUrl", undefined);

  const agentAvator = get(property, "agentProfile.profileImageUrl", "");

  const videos: Video[] =
    has(property, "videos") && property.videos ? property.videos : [];
  const showVideos: boolean = !isEmpty(videos) && videos.length > 0;
  const validUrl = require("valid-url");
  const VideoList: any = Object.keys(videos).map((key) => {
    const video: Video = videos[key];
    const isValidUrl =
      !isEmpty(video) && !isEmpty(video.url) && validUrl.isUri(video.url);
    return isValidUrl ? <VideoPlayer url={video.url} key={key} /> : null;
  });

  const address =
    has(property, "address.suburb") && has(property, "address.city")
      ? ` in ${property.address.suburb}, ${property.address.city}`
      : "";

  const propertyTypeAndAddress = `${
    property.hasUnits ? "Multiple " : ""
  }${capitalize(property.propertyType)} ${
    property.hasUnits ? "Units " : ""
  } for ${property.listingType.toLowerCase()}${address}`;

  const propertyOverView = get(property, "propertyOverView", []);

  let floorSize = get(property, "size", null);
  if (isEmpty(floorSize) && !isEmpty(propertyOverView)) {
    const _floorSize = propertyOverView.find(
      (item: PropertyItem) => item.name === "Floor size"
    );

    if (!isEmpty(_floorSize)) {
      floorSize = _floorSize.value;
    }
  }

  let landSize = 0;
  if (isEmpty(landSize) && !isEmpty(propertyOverView)) {
    const _erfSize = propertyOverView.find(
      (item: PropertyItem) => item.name === "Erf size"
    );

    if (!isEmpty(_erfSize)) {
      landSize = _erfSize.value;
    }
  }

  const bedrooms = isResidential ? get(unit, "bedrooms", undefined) : undefined;
  const bathrooms = isResidential
    ? get(unit, "bathrooms", undefined)
    : undefined;
  const parkings = isResidential ? get(unit, "parkings", undefined) : undefined;
  const localeSettings = get(
    property,
    "organisationObject.settings.locale",
    DEFAULT_LOCALE_SETTINGS
  );
  const listingMeasurement =
    get(property, "syndicator.doc.measurement_type") || "";
  const squaredMeasurement =
    get(MEASUREMENT_TYPES, `${listingMeasurement}`) ||
    getSquaredMeasurement(localeSettings);

  const vehicleSpecs = get(property, "syndicator.doc.specs");
  const transmission = isVehicle ? get(vehicleSpecs, "transmission") : null;
  const fuel = isVehicle ? get(vehicleSpecs, "fuel") : null;
  const mileage = isVehicle ? get(vehicleSpecs, "mileage") : null;
  const year = isVehicle ? get(vehicleSpecs, "year") : null;

  const propertyOverViewDataTable = get(
    property,
    "propertyOverView",
    undefined
  );

  const features = get(property, "features", []);
  const organisation = get(property, "organisationObject");

  const showRecommendations = get(
    organisation,
    "settings.recommendations.showRecommendations",
    false
  );

  const derivedFeatures: string[] = [];
  Object.keys(features).forEach((key) => {
    if (features[key]["isSelected"]) {
      derivedFeatures.push(features[key]["name"]);
    }
  });

  const showFullAddress = get(
    property,
    "organisationObject.settings.address.showFullAddress",
    false
  );
  const addressToShow = property.address;
  if (!showFullAddress) {
    // show address from suburb level
    addressToShow.line1 = "";
    addressToShow.line2 = "";
  }

  const StyledFeatureContent = () =>
    isVehicle ? (
      <>
        {transmission ? (
          <StyledFeature title="Transmission">
            <StyledIcon name="cogs" />
            <FeatureName>{`${transmission}`}</FeatureName>
          </StyledFeature>
        ) : null}
        {fuel ? (
          <StyledFeature title="Fuel">
            <StyledIcon name="gas-pump" />
            <FeatureName>{`${fuel}`}</FeatureName>
          </StyledFeature>
        ) : null}
        {year ? (
          <StyledFeature title="Year">
            <StyledIcon name="calendar" />
            <FeatureName>{`${year}`}</FeatureName>
          </StyledFeature>
        ) : null}
        {mileage ? (
          <StyledFeature title="Mileage">
            <StyledIcon name="tachometer-alt" />
            <FeatureName>{`${mileage}`}</FeatureName>
          </StyledFeature>
        ) : null}
      </>
    ) : isResidential ? (
      <>
        {bedrooms ? (
          <StyledFeature title="Bedrooms">
            <StyledIcon name="bed" />
            <FeatureName>{`${bedrooms}`}</FeatureName>
          </StyledFeature>
        ) : null}

        {bathrooms ? (
          <StyledFeature title="Bathrooms">
            <StyledIcon name="bath" />
            <FeatureName>{`${bathrooms}`}</FeatureName>
          </StyledFeature>
        ) : null}

        {parkings ? (
          <StyledFeature title="Parking">
            <StyledIcon name="car-side" />
            <FeatureName>{`${parkings}`} </FeatureName>
          </StyledFeature>
        ) : null}

        {floorSize ? (
          <StyledFeature title="Floor Size">
            <StyledIcon name="clone" />
            <SizeFeatureName>{`${formatNumber(
              floorSize,
              localeSettings
            )} ${squaredMeasurement}`}</SizeFeatureName>
          </StyledFeature>
        ) : null}

        {landSize ? (
          <StyledFeature title="Erf Size">
            <StyledIcon name="ruler-combined" size="sm" />
            <SizeFeatureName>{`${formatNumber(
              landSize,
              localeSettings
            )} ${squaredMeasurement}`}</SizeFeatureName>
          </StyledFeature>
        ) : null}
      </>
    ) : (
      <>
        {floorSize ? (
          <StyledFeature title="Floor Size">
            <StyledIcon name="clone" />
            <SizeFeatureName>{`${formatNumber(
              floorSize,
              localeSettings
            )} ${squaredMeasurement}`}</SizeFeatureName>
          </StyledFeature>
        ) : null}
        {landSize ? (
          <StyledFeature title="Erf Size">
            <StyledIcon name="ruler-combined" size="sm" />
            <SizeFeatureName>{`${formatNumber(
              landSize,
              localeSettings
            )} ${squaredMeasurement}`}</SizeFeatureName>
          </StyledFeature>
        ) : null}
      </>
    );

  return (
    <>
      {urlParams.get("preview_token") && (
        <PreviewMode>
          <PreviewText>
            Preview mode: this is how people will see your listing
          </PreviewText>
        </PreviewMode>
      )}
      {IsMobile() && (
        <UnitListGallery
          {...{
            images: property.images,
            listingId: property._id,
            isSyndicatedProperty,
          }}
        />
      )}
      <StyledPaper rounded={true}>
        {!IsMobile() && (
          <UnitListGallery
            {...{
              images: property.images,
              listingId: property._id,
              isSyndicatedProperty,
            }}
          />
        )}
        <DescriptionContainer>
          <Grid container={true}>
            <StyledLeftGrid item={true} sm={8}>
              <HeaderContainer>
                <GroupedComponent>
                  <GroupedHeader>
                    <PropertyName>{property.title}</PropertyName>
                    <Location>{propertyTypeAndAddress}</Location>
                  </GroupedHeader>
                </GroupedComponent>
              </HeaderContainer>
              <ContentCainter>
                <RoomFeatures>
                  <StyledFeatureContent />
                </RoomFeatures>
                <Greyline />
                <StyledActionsHeaderContainer>
                  <AuctionOverviewTable
                    data={get(property, "auction")}
                    organisation={get(property, "organisationObject")}
                    listingType={get(property, "listingType", "sale")}
                  />
                </StyledActionsHeaderContainer>
                <ParagraphContainer>
                  <AboutTitle>
                    About this {isVehicle ? "vehicle" : "property"}
                  </AboutTitle>
                  <StyledParagraph>
                    {parser(description)}
                    <ReadMore
                      orgColors={getWhiteLabelOrganisationColors(
                        get(property, "organisationObject")
                      )}
                      isMore={isMore}
                      onClick={handleReadMore}
                    >
                      {isMore ? "Read Less" : "Read More"}
                    </ReadMore>
                  </StyledParagraph>
                </ParagraphContainer>
                <Greyline />
                {!isEmpty(propertyOverViewDataTable) && (
                  <StyledPropertyOverviewContainer>
                    <Styledh2>
                      {isVehicle ? "Listing" : "Property"} Overview
                    </Styledh2>
                    <PropertyOverviewTable
                      localeSettings={localeSettings}
                      listing={property}
                    />
                  </StyledPropertyOverviewContainer>
                )}
                <Greyline />
                {(isResidential || isVehicle) && !isEmpty(derivedFeatures) && (
                  <StyledFeatures>
                    <ListingFeature
                      {...{ features: derivedFeatures, organisation }}
                    />
                    <Greyline />
                  </StyledFeatures>
                )}
                {showVideos && (
                  <TourContainer>
                    <Styledh2>
                      Tour this {isVehicle ? "vehicle" : "property"}
                    </Styledh2>
                    {VideoList}
                  </TourContainer>
                )}
                <StyledAgentContainer hidden={agentProfile === null}>
                  <Styledh2>The Agent</Styledh2>
                  <PropertyAgentWidget
                    {...{
                      agentAvator,
                      agencyLogo,
                      agentName,
                      organisationName,
                      agentProfile,
                      slug,
                    }}
                  />
                  <AgentGreyline />
                </StyledAgentContainer>
                <MapContainer>
                  <Styledh2>Location</Styledh2>
                  <Location>
                    {getAddressDetailsFromListing(property.address)}
                  </Location>
                  <StyledMap
                    title={property.title}
                    address={addressToShow}
                    location={property.location}
                    showFullAddress={showFullAddress}
                  />
                </MapContainer>
              </ContentCainter>
            </StyledLeftGrid>
            <StyledRightGrid item={true} sm={4}>
              <StyledActionsContainer id="sticky-elem">
                <ContactAgentWidget
                  listingType={property.listingType}
                  isMultipleUnit={hasMultipleUnits}
                  isResidential={isResidential}
                  organisation={property.organisationObject}
                  {...{
                    agentId,
                    agentAvator,
                    agencyLogo,
                    agentName,
                    executeScroll,
                    handleOpenMessageDialog,
                    price,
                    organisationName,
                    slug,
                    agentProfile,
                    property,
                  }}
                />
                <GroupedItems>
                  <StyledIcon name="heart" size="lg" type="far" />
                  <StyledIcon name="share-alt" size="lg" type="fas" />
                </GroupedItems>
              </StyledActionsContainer>
              <StyledExtrasContainer>
                <StyledExtraWidget
                  data={flowExtrasData!}
                  allowItemClick={!isEmpty(flowExtrasData)}
                />
              </StyledExtrasContainer>
            </StyledRightGrid>
          </Grid>
        </DescriptionContainer>
        {property.hasUnits && (
          <>
            <StyledH1 ref={unitRef}>
              Available{" "}
              {!isEmpty(property.units) && property.units.length > 1
                ? "Units"
                : "Unit"}
            </StyledH1>
            <StyledHr />
            <AvailableUnitList
              unitData={property.units}
              property={enquiryProperty}
              organisation={property.organisationObject}
              agentId={agentId}
              landlord={property.landlord}
            />
          </>
        )}

        {showRecommendations && !isEmpty(recommended) && <StyledHr />}
        {showRecommendations && !isEmpty(recommended) && (
          <CarouselContainer>
            <Styledh2 style={{ marginLeft: 10, marginBottom: 20 }}>
              Similar properties from {organisationName}
            </Styledh2>
            <RecommendedCarousal
              recommended={recommended}
              organisation={property.organisationObject}
            />
          </CarouselContainer>
        )}

        {showPartners && !isEmpty(partners) && <StyledHr />}
        {showPartners && !isEmpty(partners) && (
          <CarouselContainer>
            <Styledh2 style={{ marginLeft: 10, marginBottom: 10 }}>
              Partners for your home search journey
            </Styledh2>
            <PartnersCarousal
              partners={partners}
              onClick={handlePartnerOnClick}
              organisation={get(property, "organisationObject")}
            />
          </CarouselContainer>
        )}

        {!hasMultipleUnits && (
          <DialogWithImage
            open={openSendMessageDialog}
            backgroundImage={
              !isEmpty(property.coverImageUrl)
                ? property.coverImageUrl
                : getCoverImage(property)
            }
            onClose={handleCloseMessageDialog}
            closeDialog={handleCloseMessageDialog}
            enquiry={!authenticated}
          >
            {!authenticated ? (
              <EnquiryFormContainer
                unit={unit}
                property={property}
                handleCloseMessageDialog={handleCloseMessageDialog}
                agentId={agentId}
                landlordName={
                  has(property, "landlord.name") ? property.landlord.name : ""
                }
              />
            ) : (
              <MessageFormContainer
                unit={unit}
                isResidential={isResidential}
                property={property}
                handleCloseMessageDialog={handleCloseMessageDialog}
                agentId={agentId}
                landlordName={
                  has(property, "landlord.name") ? property.landlord.name : ""
                }
              />
            )}
          </DialogWithImage>
        )}
      </StyledPaper>
      <StickyPriceTag>
        <PriceWidget
          listingType={property.listingType}
          isMultipleUnit={hasMultipleUnits}
          isResidential={isResidential}
          organisation={property.organisationObject}
          {...{
            price,
            executeScroll,
            handleOpenMessageDialog,
            agentId,
            property,
          }}
        />
      </StickyPriceTag>
    </>
  );
};

const mapStateToProps = ({ auth, dialogs }: ApplicationState) => ({
  authenticated: auth.authenticated,
  dialogs,
  user: auth.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      toggleLoginDialog: toggleLoginDialogAction,
      toggleMessageDialog: toggleMessageDialogAction,
      toggleProfileDialog: toggleProfileDialogAction,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListingDetailsView);
