import _, { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { DEFAULT_LOCALE_SETTINGS } from "../../utils/defaultLocaleSettings";
import { formatPropertyAmountByLocale } from "../../utils/numberFormatter";
import styled, { mediaDown } from "../../utils/styledComponents";
import AuctionDetailsTable from "../tables/auctionDetailsTable";
import { getWhiteLabelOrganisationColors } from "../../utils/whiteLabel";
import Button from "../buttons/Button";
import { isNumeric } from "../../utils/general";
import { Listing } from "../../store/listings";
import Cookies from "js-cookie";

const StyledPriceContainer = styled.div`
  /* margin-bottom: 12px; */
`;
const StyledPriceAmount = styled.span`
  font-size: 24px;
  font-family: ${({ theme }) => theme.fonts.MS700};
  font-weight: ${({ theme }) => theme.fontWeights.MS700};
  color: ${({ theme }) => theme.colors.grey4};
`;

const Children = styled.div`
  && {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const StyledButton = styled(Button)`
  && {
    border-radius: 10px;
    padding: 13px;
    min-width: 100%;
    text-align: center;
    text-transform: none;
    cursor: pointer;
    font-family: ${({ theme }) => theme.fonts.MS500};
    font-size: 12px;
  }
`;

const Container = styled.div<{ isAccepted: boolean }>`
  && {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: ${({ isAccepted }) => (isAccepted ? "0" : "-80px")};
    ${mediaDown.md`
      align-items: center;
      flex-direction: row; 
      position: relative;
      background: white;
      width: 100%;
    `}
  }
`;

const AuctionDetailsContainer = styled.div`
  && {
    margin-top: 2.5px;
    ${mediaDown.md`
      display: none;
    `}
    ${mediaDown.sm`
      display: none;
    `}
  }
`;

const StyledLabelContainer = styled.div`
  && {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.grey2};
    font-family: ${({ theme }) => theme.fonts.MS700};
    font-weight: ${({ theme }) => theme.fontWeights.MS700};
    line-height: 26px;
    border-bottom: 0px;
    padding: 0px;
    ${mediaDown.md`
      display: none;
    `}
    ${mediaDown.sm`
      display: none;
    `}
  }
`;

export interface PriceWidgetProps {
  listingType: string;
  isResidential: boolean;
  isMultipleUnit: boolean;
  price: number | string;
  organisation: object;
  agentId: string;
  property: Listing;
  handleOpenMessageDialog: () => void;
  executeScroll: () => void;
}

const PriceWidget: React.FunctionComponent<PriceWidgetProps> = ({
  agentId,
  price,
  isResidential,
  organisation,
  listingType,
  isMultipleUnit,
  property,
  executeScroll,
  handleOpenMessageDialog,
}) => {
  const localeSettings = _.get(
    organisation,
    "settings.locale",
    DEFAULT_LOCALE_SETTINGS
  );
  const isAuction = _.get(property, "listingType", "sale") === "auction";

  let amountText = price;
  if (isNumeric(price)) {
    amountText = `${
      isMultipleUnit ? "from " : ""
    }${formatPropertyAmountByLocale(property, localeSettings)}`;
  }

  const contactButtonText = isResidential ? "Contact Agent" : "Contact Broker";
  const colors = getWhiteLabelOrganisationColors(organisation);

  const [isAccepted, setIsAccepted] = useState<boolean>(false);

  const checkCookie = () => {
    const popiActCookieString = Cookies.get("popiAct");
    if (popiActCookieString) {
      const popiActCookie = JSON.parse(popiActCookieString);
      setIsAccepted(!!popiActCookie.accepted);
    }
  };

  useEffect(() => {
    checkCookie();

    const interval = setInterval(() => {
      checkCookie();
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <Container isAccepted={isAccepted}>
      {isAuction && localeSettings.currency.code !== "AUD" && (
        <StyledLabelContainer>Starting Bid</StyledLabelContainer>
      )}
      <StyledPriceContainer>
        <StyledPriceAmount>{amountText}</StyledPriceAmount>
      </StyledPriceContainer>
      {isAuction && (
        <AuctionDetailsContainer>
          <AuctionDetailsTable
            data={_.get(property, "auction")}
            organisation={organisation}
            listingType={listingType}
          />
        </AuctionDetailsContainer>
      )}
      <Children>
        {isMultipleUnit ? (
          <StyledButton
            orgColors={colors}
            onClick={executeScroll}
            primary={true}
          >
            View all listings
          </StyledButton>
        ) : (
          !isEmpty(agentId) && (
            <StyledButton
              orgColors={colors}
              onClick={handleOpenMessageDialog}
              primary={true}
            >
              {contactButtonText}
            </StyledButton>
          )
        )}
      </Children>
    </Container>
  );
};

export default PriceWidget;
